import React, { Fragment } from 'react'

import { createTheme } from '@platform-ui-kit/components-library'
import { RangeOf } from '@platform-ui-kit/components-library/dist/types/types/numberRange'
import {
  WppButton,
  WppCheckbox,
  WppActionButton,
  WppTypography,
  WppIconPlus,
  WppToggle,
  WppPillGroup,
  WppListItem,
  WppTag,
  WppPill,
  WppIconFavorites,
  WppIconLike,
  WppIconPremium,
  WppDatepicker,
  WppSelect,
  WppFilterButton,
  WppInlineMessage,
  WppIconPitch,
  WppIconWorkshop,
  WppIconPieChart,
  WppBackToTopButton,
  WppFloatingButton,
  WppIconExtension,
  WppInput,
  WppIconDiversity,
} from '@platform-ui-kit/components-library-react'

import { useAppContext } from 'app/context'
import { getThemeFromCreateThemeInfo } from 'pages/manage-theme/utils'

import styles from './ColorsPreview.module.scss'

const TAGS = [
  {
    label: 'Lab 1',
    icon: <WppIconPitch slot="icon-start" />,
  },
  {
    label: 'Tag 2',
    icon: <WppIconWorkshop slot="icon-start" />,
  },
  {
    label: 'Lab 3',
    icon: <WppIconPieChart slot="icon-start" />,
  },
  {
    label: 'Tag 4',
    icon: <WppIconExtension slot="icon-start" />,
  },
  {
    label: 'Lab 5',
    icon: <WppIconDiversity slot="icon-start" />,
  },
  {
    label: 'Tag 6',
    icon: <WppIconDiversity slot="icon-start" />,
  },
  {
    label: 'Tag 7',
    icon: <WppIconPitch slot="icon-start" />,
  },
  {
    label: 'Tag 8',
    icon: <WppIconWorkshop slot="icon-start" />,
  },
  {
    label: 'Tag 9',
    icon: <WppIconPieChart slot="icon-start" />,
  },
]

export const ColorsPreview = () => {
  const appContext = useAppContext()
  const theme = createTheme(getThemeFromCreateThemeInfo(appContext) as any)

  return (
    <Fragment>
      <WppTypography type="2xs-strong" className={styles.sectionName}>
        COLOURS
      </WppTypography>
      <div style={theme} className={styles.sectionWrapper}>
        <div className={styles.buttons}>
          <WppButton>
            Primary
            <WppIconPlus slot="icon-start" />
          </WppButton>
          <WppButton variant="secondary">
            Secondary
            <WppIconPlus slot="icon-start" />
          </WppButton>
          <WppActionButton>
            Primary
            <WppIconPlus slot="icon-start" />
          </WppActionButton>
          <WppBackToTopButton />
          <WppFloatingButton disabled />
        </div>
        <div className={styles.buttonsBottom}>
          <WppActionButton variant="secondary">
            Secondary
            <WppIconPlus slot="icon-start" />
          </WppActionButton>
          <WppFilterButton>
            Filters
            <WppIconPlus slot="icon-start" />
          </WppFilterButton>
          <WppCheckbox required checked labelConfig={{ text: 'Checkbox' }} />
          <WppToggle required checked labelConfig={{ text: 'Toggle' }} />
        </div>
        <WppPillGroup className={styles.pillGroup} type="multiple" value={['item-a', 'item-b', 'item-c', 'item-d']}>
          <WppPill label="Item A" value="item-a">
            <WppIconFavorites slot="icon-start" />
          </WppPill>
          <WppPill label="Item B" value="item-b">
            <WppIconLike slot="icon-start" />
          </WppPill>
          <WppPill label="Item C" value="item-c">
            <WppIconExtension slot="icon-start" />
          </WppPill>
          <WppPill label="Item D" value="item-d">
            <WppIconPremium slot="icon-start" />
          </WppPill>
        </WppPillGroup>
        <div className={styles.complexityInputs}>
          <WppSelect className={styles.multipleSelect} required value="text" labelConfig={{ text: 'Label' }}>
            <WppListItem value="car">
              <p slot="label">Car</p>
            </WppListItem>
            <WppListItem value="house" disabled>
              <p slot="label">House</p>
            </WppListItem>
            <WppListItem value="apartment">
              <p slot="label">Apartment</p>
            </WppListItem>
            <WppListItem value="text">
              <p slot="label">Text</p>
            </WppListItem>
          </WppSelect>
          <WppDatepicker className={styles.datepicker} required labelConfig={{ text: 'Label' }} range />
        </div>
        <div className={styles.inlineMessages}>
          <WppInlineMessage className={styles.inlineMessage} message="Warning message" type="warning" size="m" />
          <WppInlineMessage className={styles.inlineMessage} message="Error message" type="error" size="m" />
          <WppInlineMessage className={styles.inlineMessage} message="Success message" type="success" size="m" />
        </div>
        <div className={styles.inputs}>
          <WppInput className={styles.input} value="Text" messageType="warning" message="Warning message" />
          <WppInput className={styles.input} value="Text" messageType="error" message="Error message" />
          <WppButton variant="destructive">Destructive</WppButton>
        </div>
        <div className={styles.tags}>
          {TAGS.map((item, index) => (
            <WppTag label={item.label} categoricalColorIndex={(index + 1) as RangeOf<9>}>
              {item.icon}
            </WppTag>
          ))}
        </div>
      </div>
    </Fragment>
  )
}
